.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
}

.social-links {
    display: flex;
    gap: 4rem; /* Adjust the gap between logos as needed */
    align-items: center;
}

.social-links>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
 
}


.logo-f {
    margin-top: 20px; /* Adjust the margin as needed */
    display: flex;
    gap: 20px; /* Adjust the gap between logos as needed */
}

.logo-f img {
    width: 10rem; /* Adjust the width of the logos as needed */
    height: auto;
}
.blur-f-1{
    bottom: 0rem;
    right: 15%;
    width: 30rem;
    height: 12rem;filter: blur(200px);
    background-color: rgb(229, 143, 68);


}

.blur-f-2{
    bottom: 0rem;
    right: 2rem;
    width: 20rem;
    height: 12rem;filter: blur(200px);
    background-color: rgb(184, 99, 43);


}